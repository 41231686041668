import React, { Fragment } from "react";
import Container from "../../../Components/UI/Container";
import { Helmet } from "react-helmet";
import { Card } from "react-bootstrap";
import Director from "../../../assets/images/default.jpg";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

function Chairman() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Container width="1250px">
        <Helmet>
          <title>
            {t(`Chairman,_BOG`)} | {t("NIT Jamshedpur")} | राष्ट्रीय
            प्रौद्योगिकी संस्थान जमशेदपुर
          </title>
          <meta
            name="keywords"
            content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,chairman,bog"
          />
          <meta name="description" content="The chairman of N.I.T. Jsr." />
        </Helmet>
        <Card className="mt-5 mx-3">
          <Card.Img
            variant="center"
            src={Director}
            style={{ height: 350, display: "block", margin: "30px auto 0" }}
          />
          <Card.Title style={{ display: "block", margin: "0 auto" }}>
            <Card.Body style={{ display: "block", margin: "0 auto" }}>
              <center>
                <div
                  style={{
                    margin: 0,
                    paddingBottom: "10px",
                    fontSize: "1.2rem",
                  }}
                >
                  <b>{t("Chairman-BOG")}</b>
                </div>
                <div>
                  {/* {t("Chairman-BOG-content")} */}
                  <br></br>
                  <div style={{ fontSize: "1.1rem" }}>
                    Contact : chairman.bog@nitjsr.ac.in
                  </div>
                </div>
              </center>
            </Card.Body>
            {/* <p
              style={{
                textAlign: "justify",
                padding: "0.5 rem",
                margin: "10px",
              }}
            >
              Mr. T. Krishna Prasad is currently serving as Chairman BOG, NIT
              Jamshedpur. Mr. Prasad earned a Bachelor of Mechanical Engineering
              degree from National Institute of Technology Warangal, India in
              1983 and a PGDM degree from Indian Institute of Management,
              Ahmedabad in 1985. He also has his LLB from Osmania University in
              2019 and doing Ph.D. in Sustainable Urban Mobility from Osmania
              University.
              <br />
              He is an IPS officer of 1986 batch, Telangana Cadre. He is Former
              DGP &amp; Chairman Road Safety Authority Telangana State. He
              decorated with prestigious Indian Police Medal, President’s Police
              Medal and Antrik Suraksha Seva Padak for surrender and
              rehabilitation of Naxal movement members. He is also recognized
              twice by IIMA Alumni Hyderabad Chapter as Distinguished Alumni
              (2008 &amp; 2018) and Recognized as Distinguished Alumni by NIT
              Warangal (2019). As an IPS officer he has contributed as a
              District Superintendent of Police (Nellore, Vizag, Guntur and
              Medak) and DIG of Warangal Range (4 districts) and Vizag Range (3
              districts) and executed the Surrender and rehabilitation of more
              than 100 Naxals. He also serves as Inspector General of Police
              (Served as IG Grey Hounds, APPA, APSRTC) (2008 – 2012) and
              Additional Director General and Chief of CID of United AP (2012 –
              2014). As an Officer he has also contributed in industrial
              development of AP and Telangana. He was CMD Leather Industries
              Corporation of AP. Conceptualized and implemented MALUPU program
              who trained about 1,62,000 Below the Poverty Line SC leather
              artisans and created Leather Clusters in 5000 Acres. He also
              worked as Executive Director, AP Industrial Infrastructure
              Corporation, participated in development of several infrastructure
              projects including: (i) Hyderabad International Airport (ii) Cyber
              Towers and HITEC City (iii) Hyderabad Financial District (iv)
              Hyderabad Golf Course – Boulder Hills (v) Hyderabad International
              Convention Centre (HICC) and HITEX (vi) Gangavaram Port (vii)
              Krishnapatnam Port and 17 other mega Infrastructure Projects. Sri
              T. KRISHNA PRASAD, IPS is a highly qualified and accomplished
              Officer with exemplary qualities of leadership. He is highly
              innovative and reputed for taking his organization to greater
              heights. He specializes in Road Safety and the development of
              Infrastructure Projects under Public Private Partnership mode.
            </p> */}
            <a
              href="https://www.nitjsr.ac.in/backend/uploads/uploads_2023/chairman_data/Shri.%20T.%20Krishna%20Prasad_Chairperson_BOG%20of%20NIT%20Jamshedpur_CV.pdf"
              target="__blank"
            >
              {/* <div className="learn__more-btn">
                {" "}
                <span className="hyphen" />
                <span className="btn_text">{t("show_more_button")}</span>
              </div> */}
              {/* <span className="btn_text">{t("read_more_button")}</span> */}
            </a>
          </Card.Title>
        </Card>
      </Container>
    </Fragment>
  );
}

export default Chairman;
