import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import Sticky from "react-stickynode";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/HomeNavbar/index";
import DrawerSection from "../../Components/Layout/DrawerSection";
import FeatureSection from "./FeatureSection";
import VisionAndMission from "./VisionAndMission";
import NoticeSection from "./NoticeSection";
import ResearchSection from "./ResearchSection";
import EventSection from "./EventSection";
import AchievementAndPublication from "./AchievementAndPublicationSection";
import CurrentAchievement from "./CurrentAchievement";
// import MilestoneBlock from "./MilestoneBlock";
import ClientBlock from "./ClientBlock";
import Footer from "../../Components/Layout/Footer";
// import Bulletin from "./Bulletin/Bulletin";
import SocialMediaSection from "../../Components/Layout/SocialMedia";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import mmmd from "../../assets/images/smallBanner/mmmd.png";
import img1 from "../../assets/images/smallBanner/LOGO_IAC.png";
import img2 from "../../assets/images/smallBanner/nirf2023_banner.jpg";
import img3 from "../../assets/images/smallBanner/img3.jpeg";
import HighlightedNotice from "./HighlightedNoticesSection";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Homeindex() {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {" "}
          {t("NIT Jamshedpur")} | राष्ट्रीय प्रौद्योगिकी संस्थान जमशेदपुर
        </title>
        <meta
          name="description"
          content="Official Website: Information about programs offered, departments, activities, research, consultancy, admissions, events, and contacts."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,technology,competitions,events,workshop,exhibition,science,fest,technical,culfest,ojass,utkarsh,robotics,sports meet,acm,csi,pizza hackers,daksh,drift,lds,cds,manthan,mes,technica,topguns,faces,phocus,qunite,revanta,sece,innoreva"
        />
      </Helmet>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />
        {/* End of Main head section */}
        {/* Start Main wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection black="black" white="white" />
          </DrawerProvider>
          <div style={{ maxWidth: "100%" }}>
            <ContentWrapper>
              <SocialMediaSection/>
              {/* <BannerSection /> */}
              <NoticeSection/>
              {/* <Bulletin /> */}
              <div className="container mt-3 text-center">
                <div className="row">
                  <div className="col mb-3 col-12 col-md-4">
                    <a
                        href="https://www.iacnitjsr.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <img
                          style={{height: "100px"}}
                          src={img1}
                          alt="meri maati mera desh"
                      />
                    </a>
                  </div>
                  <div className="col mb-3 col-12 col-md-4">
                    <a
                        href="https://www.nitjsr.ac.in/Institute/Ranking_and_Recognition"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <img src={img2} alt="nirf_ranking"/>
                    </a>
                  </div>
                  <div className="col mb-3 col-12 col-md-4">
                    <a
                        href="http://online.nitjsr.ac.in/faculty/CandidateLogin/Login.aspx"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      <span style={{display: "none"}}>
                        Recruitment_for_faculty
                      </span>
                      <img src={img3} alt="Recruitment_for_faculty"/>
                    </a>
                  </div>
                </div>
              </div>
              <HighlightedNotice/>

              {/* <div className="container mt-3 text-secondary text-center">
                <strong>
                Industry of Academia Conclave(IAC) 2023 Live Streaming :
                </strong>{" "}
                <a href="https://www.youtube.com/watch?v=gwZL20iFzrM" target="_blank" rel="noopener noreferrer">Link</a>
              </div> */}
              {/* <div className="container mt-3 text-secondary text-center">
                <strong>
                  Convocation 2023 Live Streaming :
                </strong>{" "}
                <a href="https://youtube.com/live/T5iHq_Kesho?feature=share" target="_blank" rel="noopener noreferrer">Link</a>
              </div> */}

              <div className="container mt-3 text-secondary text-center">
                <strong>
                  Donate/Sponsor to NIT Jamshedpur :
                </strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://nitjsr.ac.in/backend/files/SOP_For_making_Donations_sponsorship_to_National_Institute_of_Technology.pdf"
                    rel="noreferrer"
                    target={"_blank"}
                >
                  {"Link"}
                </a>
              </div>

              {/*<div className="container mt-3 text-secondary text-center">
                <strong>
                  Rolling Advertisement for Recruitment of Assistant Professor
                  Post :
                </strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://online.nitjsr.ac.in/faculty2024"
                    rel="noreferrer"
                    target={"_blank"}
                >
                  {"Link"}
                </a>
              </div>*/}
              <div className="container mt-3 text-secondary text-center">
                <strong>
                QIP Center, NIT Jamshedpur, Detailed Guidelines for Admission (M.Tech & PhD Program, 2025):
                </strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://qip.aicte.gov.in/assets/manuals/QIP_%20Scheme%20Guidelines_%202025.pdf "
                    rel="noreferrer"
                    target={"_blank"}
                >
                  {"Link"}
                </a>
              </div>
              {/* <div className="container mt-3 text-secondary text-center">
                <strong>PhD admissions for the Academic Year 2024-25</strong>{" "}
                <a
                  style={{ paddingRight: "10px" }}
                  href="http://202.168.87.80/backend/uploads/recents/INFORMATION%20BROCHURE%20FOR%20PhD%20ADMISSIONS%20IN%20NIT%20JAMSHEDPUR%20FOR%20THE%20AUTUMN%20SEMESTER%20OF%20AY%202024-2025.pdf"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Link"}
                </a>
              </div> */}
              <div
                  className="container mt-3 text-secondary text-center"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1em",
                  }}
              >
                <div>
                  <strong>NIRF submitted data :</strong>{" "}
                  <a
                      style={{paddingRight: "10px"}}
                      href="https://www.nitjsr.ac.in/Institute/Ranking_and_Recognition"
                      rel="noreferrer"
                      target={"_blank"}
                  >
                    {"Link"}
                  </a>
                </div>

                <div>
                  <strong>List of events : 2024</strong>{" "}
                  <Link to="/events">Links</Link>
                </div>
              </div>
              {/* <div
                className="container mt-3 text-secondary text-center"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1em",
                }}
              >
                <div>
                  <strong>Convocation 2024:</strong>{" "}
                  <a
                    style={{ paddingRight: "10px" }}
                    href="https://online.nitjsr.ac.in/convocation2024/default.aspx"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {"Link"}
                  </a>
                </div>

                <div>
                  <strong>Convocation: 2024 Live</strong>{" "}
                  <a
                    style={{ paddingRight: "10px" }}
                    href="https://youtube.com/live/bxiEpNag6_g?feature=share"
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {"Link"}
                  </a>
                </div>
              </div> */}
              {/* <div className="container mt-3 text-secondary text-center">
                <div>
                  <strong>Convocation 2024: Gallery</strong>{" "}
                  <a
                      style={{paddingRight: "10px"}}
                      href="https://drive.google.com/drive/folders/1yayR3yuWF3-QJwqPQts2oToV6Oz9G75S?usp=sharing"
                      rel="noreferrer"
                      target={"_blank"}
                  >
                    Link
                  </a>
                </div>
              </div> */}

              {/* <div className="container mt-3 text-secondary text-center">
                <div>
                  <strong>PhD. Admissions for Spring Semester</strong>{" "}
                  <a
                      style={{paddingRight: "10px"}}
                      href="https://nitjsr.ac.in/backend/uploads/phd%20important%20links/UPDATED%20INFORMATION%20BROCHURE%20-%20PhD%20ADMISSIONS%20FOR%20THE%20SPRING%20SEMESTER%20OF%20AY%202024-2025%20AT%20NIT%20JAMSHEDPUR.pdf"
                      rel="noreferrer"
                      target="_blank"
                  >
                    Link
                  </a>
                </div>
              </div> */}

              {/* <div className="container mt-3 text-secondary text-center">
                <strong>
                  Non-teaching recruitment(Last Date for Submission of Online
                  Application is 06/09/2023.):
                </strong>{" "}
                <a
                  style={{ paddingRight: "10px" }}
                  href="https://nitjsr.ac.in/backend/uploads/recruitments/National_Institute_of_Technology_NIT_Jamshedpur%20(1).pdf"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Link"}
                  <span style={{ paddingLeft: "10px" }}>|</span>
                </a>
                <a
                  style={{ paddingRight: "10px" }}
                  href="https://nitjsr.ac.in/backend/uploads/recruitments/Public_Notice-Conduct_of_CBT_Examination_for_Non-Teaching_Positions.pdf"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Public Notice"}
                </a>
                <span style={{ paddingRight: "10px" }}>|</span>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/recruitments/Syllabus%20&%20Scheme%20of%20Recruitment%20Exam.pdf"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Process Details "}
                </a>
                <span style={{ paddingRight: "10px" }}>|</span>
                <a
                  href="https://crenit.samarth.ac.in/index.php/site/landing-page"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Apply Online"}
                </a>
                <span style={{ paddingRight: "10px" }}>|</span>
                <a
                  href="https://nitjsr.ac.in/backend/uploads/recruitments/57d25ced-d733-46d7-9237-68cbd3503ead-Examination%20Schedule%20%20for%20Common%20Recruitment%20Examination%202023.pdf"
                  rel="noreferrer"
                  target={"_blank"}
                >
                  {"Schedule"}
                </a>
              </div> */}

              {/* <div className="container mt-3 text-secondary text-center">
              <strong>
                PhD Programme Admission - AY 2023-24 (Second Round) :
              </strong>{" "}
              <a
                href="http://online.nitjsr.ac.in/phd2023_2/CandidateLogin/Login.aspx"
                rel="noreferrer"
                target={"_blank"}
              >
                {"PG Link"}
              </a>
            </div> */}
              {/* <div className="container mt-3 text-secondary text-center">
              <strong>
                PhD Programme Admission - AY 2023-24 (Second Round):
              </strong>{" "}
              <a
                href="http://online.nitjsr.ac.in/phd2023_2/CandidateLogin/Login.aspx"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a>
            </div> */}
              {/*<div className="container mt-3 text-secondary text-center">*/}
              {/*  <strong>Convocation 2024 Notices :</strong>{" "}*/}
              {/*  <Link to="/convocation">{"Link"}</Link>*/}
              {/*</div>*/}
              {/*<br/>*/}
              <div className="container mt-3 text-secondary text-center">
                <strong>Study in India :</strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://studyinindia.gov.in/"
                    rel="noreferrer"
                    target={"_blank"}
                >
                  Link
                </a>
              </div>
              {/* <br /> */}
              {/* <div className="container mt-3 text-secondary text-center">
                <strong>Industry Academia Conclave 2024</strong>{" "}
                <a
                    style={{paddingRight: "10px"}}
                    href="https://www.youtube.com/live/x6DFn3YOTss?si=PwlhneHCP-Gz7U8T"
                    rel="noreferrer"
                    target={"_blank"}
                >
                  Link
                </a>


              {/* <br /> */}

              {/* <div className="container mt-3 text-secondary text-center">
              <strong>M. Tech Admissions 2023 at NIT Jamshedpur:</strong>{" "}
              <a
                href="http://www.nitjsr.ac.in/Academic/M.Tech_Admission"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>M.Sc. Admissions 2023 at NIT Jamshedpur:</strong>{" "}
              <a
                href="http://www.nitjsr.ac.in/Academic/M.Sc._Admission"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a>
            </div>
            {/* <div>
              <button onClick={<MediaCoverage />}>Media Section </button>
            </div> */}
              {/* <div className="container mt-3 text-secondary text-center">
              <strong>
                 Admission to Ph.D. Programme for Autumn Semester 2023-2024:
              </strong>{" "}
              <a
                href="https://online.nitjsr.ac.in/phd2023/CandidateLogin/Login.aspx"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a>
            </div> */}

              {/* <div className="container mt-3 text-secondary text-center">
              <strong>
                The Ph.D result will be declared on Monday (18-07-2022).
              </strong>
            </div> */}
              {/* <div className="container mt-3 text-secondary text-center">
              <strong>NIRF 2022 INDIA RANK (ENGINEERING) :</strong>{" "}
              <a
                href="https://www.nitjsr.ac.in/backend/uploads/old_rankings/NIRF/2022/NIRF-2022%20INDIA%20RANK%20(ENGINEERING).pdf"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a>
            </div> */}
              <FeatureSection/>
              {/* <BranchSection /> */}
              <VisionAndMission/>
              {/* <ResearchSection /> */}
              <AchievementAndPublication/>
              <EventSection/>
              {/* <CurrentAchievement /> */}
              {/* <Testimonial /> */}
              {/* <VisionAndMission /> */}
              {/* <MilestoneBlock /> */}
              <ClientBlock/>
            </ContentWrapper>
          </div>
          <Footer/>
        </CharityWrapper>
        {/* End of Main wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
}

export default Homeindex;
